const Toast = ({ feedback }) => {
  return (
    <div className="toast hide">
      <p className="select-none">
        {feedback}
      </p>
    </div>
  )
}

export default Toast