import "tailwindcss/tailwind.css"
import './App.scss';
import { useState, useEffect } from 'react';
// Components
import Nav from './components/layout/navigation/Nav';
import Toast from './components/layout/toast/Toast';
import About from './components/about/About';
import Techs from './components/techs/Techs';
import Projects from './components/projects/Projects';
import Contact from './components/contact/Contact';
// Logo
import { ReactComponent as Logo } from './assets/logos/logo.svg'

function App() {
  const [activeNav, setActiveNav] = useState("about");
  const [techTab, setTechTab] = useState("LANGUAGES");
  const [activeProject, setActiveProject] = useState(1);
  const [isOpen, setIsOpen] = useState(false);
  const [feedback, setFeedback] = useState(null);
  const [toast, setToast] = useState(null);
  
  useEffect(() => {
    if(toast === null) {
      setToast(document.querySelector('.toast'));
    }
    // eslint-disable-next-line
  },[]);

  const hideNotif = () => {
    toast.classList.remove('show');
    toast.classList.add('hide');
  }
  
  const showNotif = () => {
    toast.classList.remove('hide');
    toast.classList.add('show');
    setTimeout(hideNotif, 2000);
  }

  return (
    <div className="App relative w-screen h-screen overflow-hidden">
      <Toast feedback={feedback} />
      <Nav activeNav={activeNav} isOpen={isOpen} setActiveNav={setActiveNav} setIsOpen={setIsOpen} />
      <Logo id="logo-watermark" />
      <div id="scrollable-content" className="w-screen h-full overflow-y-scroll overflow-x-hidden ">
        <About activeNav={activeNav} />
        <Techs activeNav={activeNav} techTab={techTab} setTechTab={setTechTab} />
        <Projects activeNav={activeNav} activeProject={activeProject} setActiveProject={setActiveProject} />
        <Contact activeNav={activeNav} showNotif={showNotif} setFeedback={setFeedback} />
      </div>
    </div>  
  );
}

export default App;