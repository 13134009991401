import { languages, frameworks, tools } from './TechsData';
import { Trail, config } from 'react-spring/renderprops';

import React from 'react'

const Techs = ({ activeNav, techTab, setTechTab }) => {
  const tabs = ["LANGUAGES", "FRAMEWORKS", "TOOLS"];

  // Handler for Tab Selection
  const onTabSelect = e => {
    const tab = e.target.innerText;
    setTechTab(tab);
  }

  return (
    <section id="techs" className={activeNav === "technologies" ? "visible" : "hidden"}>
      <h2 className="text-watermark absolute select-none">Techs</h2>
      <div className="container w-11/12 md:w-4/5 mx-auto h-full flex flex-col justify-between sm:flex-row">
        {/* Tabs */}
        <div className="flex w-full justify-center h-max sm:w-1/2 sm:items-center sm:h-full">
          <div className="tabs pointer-events-auto flex flex-col w-10/12 items h-full justify-center">
            {/* Tabs Animation  */}
            {activeNav === "technologies" && 
              <Trail
                items={tabs}
                keys={item => item}
                from={{ position: 'relative', transform: 'scale(0)', opacity: 0 }}
                to={{ transform: 'scale(1)', opacity: 1 }}
                config={config.wobbly}
                reset={true}
                >
                {tab => (props) => (
                  <div style={props} key={tab} className={techTab === tab ? "tab active font-semibold w-max" : "tab font-semibold w-max"} onClick={onTabSelect} >
                    {tab}
                  </div>
                  
                )}
              </Trail>
            }
          </div>
        </div>

        <div className="flex flex-col justify-center w-full h-full sm:w-1/2 sm:h-full">
          {/* Languages */}
          {techTab === "LANGUAGES" && (            
            <div className="techs flex flex-rows flex-wrap items-center justify-center">
              {/* Languages Animation */}
              {activeNav === "technologies" && 
                <Trail
                  items={languages}
                  keys={item => item.name}
                  from={{ transform: 'scale(2)', opacity: 0 }}
                  to={{ transform: 'scale(1)', opacity: 1 }}
                  delay={500}
                  config={config.stiff}
                  reset={true}
                  >
                  {language => (props) => (
                    <div style={props} className="tech-item flex flex-col md:w-3/12 w-4/12 items-center my-3 md:my-6" key={language.name}>
                      <img className="h-10 md:h-12" src={language.logo} alt={language.name}/>
                      <p className="text-xs mt-2 sm:mt-3 sm:text-sm tracking-widest text-gray-400">{language.name}</p>
                    </div>
                  )}
                </Trail>
              }
            </div>
          )}
          
          {/* Frameworks */}
          {techTab === "FRAMEWORKS" && (
            <div className="techs flex flex-rows flex-wrap items-center justify-center">
              {/* Frameworks Animation */}
              {activeNav === "technologies" && 
                <Trail
                  items={frameworks}
                  keys={item => item.name}
                  from={{ transform: 'scale(2)', opacity: 0 }}
                  to={{ transform: 'scale(1)', opacity: 1 }}
                  delay={500}
                  config={config.stiff}
                  reset={true}
                  >
                  {framework => (props) => (
                    <div style={props} className="tech-item flex flex-col md:w-3/12 w-4/12 items-center my-3 md:my-6" key={framework.name}>
                      <img className={framework.name === "ExpressJS" ? "mt-6 mb-2 h-4" : "h-10 md:h-12"} src={framework.logo} alt={framework.name}/>
                      <p className="text-xs mt-2 sm:mt-3 sm:text-sm tracking-widest text-gray-400">{framework.name}</p>
                    </div>
                  )}
                </Trail>
              }
            </div>
          )}

          {/* Tools */}
          {techTab === "TOOLS" && (
            <div className="techs flex flex-rows flex-wrap items-center justify-center">
              {/* Tools Animation */}
              {activeNav === "technologies" && 
                <Trail
                  items={tools}
                  keys={item => item.name}
                  from={{ transform: 'scale(2)', opacity: 0 }}
                  to={{ transform: 'scale(1)', opacity: 1 }}
                  delay={500}
                  config={config.stiff}
                  reset={true}
                  >
                  {tool => (props) => (
                    <div style={props} className="tech-item flex flex-col md:w-3/12 w-4/12 items-center my-3 md:my-6" key={tool.name}>
                      <img className="h-10 md:h-12" src={tool.logo} alt={tool.name}/>
                      <p className="text-xs mt-2 sm:mt-3 sm:text-sm tracking-widest text-gray-400">{tool.name}</p>
                    </div>
                  )}
                </Trail>
              }
            </div>
          )}
        </div>
      </div>
    </section>
  )
}

export default Techs;