import { useState, useEffect } from 'react';
import { Spring, Trail, config } from 'react-spring/renderprops';
import { ReactComponent as Logo } from '../../../assets/logos/logo.svg'
import { ReactComponent as MenuIcon } from '../../../assets/icons/menu-icon.svg'
import { ReactComponent as CloseIcon } from '../../../assets/icons/close-icon.svg'

function Nav({ activeNav, isOpen, setActiveNav, setIsOpen  }) {
  const navLinks = ["about", "technologies", "projects", "contact"];
  const [nav, setNav] = useState(null);
  const [links, setLinks] = useState(null);
  const [indicator, setIndicator] = useState(null);
  const [isInitialized, setIsInitialized] = useState(false);
  const [isIndicatorInitialized, setIsIndicatorInitialized] = useState(false);
  const [isActiveLinkSet, setIsActiveLinkSet] = useState(false);
  const [activeLink, setActiveLink] = useState(null);
  let elem;

  useEffect(() => {
    if(!isInitialized && activeLink === null && !isIndicatorInitialized) {
      initLinks();
      setIsInitialized(true);
      // console.log("nav & links initialized");
    }

    if(isInitialized && activeLink !== null && !isIndicatorInitialized) {
      initIndicator();
      setIsIndicatorInitialized(true);
      // console.log("indicator initialized");
    }

    if(isInitialized && activeLink !== null && isIndicatorInitialized === true && !isActiveLinkSet) {
      setActiveLink(document.querySelector('.link.active'));
      setIsActiveLinkSet(true);
      // console.log("active link set");
      addEvents(links);
    }

    if(isInitialized && activeLink !== null && isIndicatorInitialized === true && isActiveLinkSet === true) {
      setActiveLink(document.querySelector('.link.active'));
      addEvents(links);
      updateIndicatorPosition();
    }
    // eslint-disable-next-line
  }, [isInitialized, isIndicatorInitialized, isActiveLinkSet, activeNav, activeLink, links])

  const initLinks = () => {
    // Get reference of the main-nav
    setNav(document.getElementById("main-nav"));
    // Get reference to nav links
    setLinks(document.querySelectorAll(".link"));
    // Set Active Link
    setActiveLink(document.querySelector('.link.active'));
  }

  const initIndicator = () => {
    // Create indicator element
    elem = document.createElement("li");
    elem.id = "indicator";
    // Set Indicator element left position
    const origLeft = activeLink.offsetLeft;
    const center = activeLink.offsetWidth / 2;
    const leftPos = origLeft + center;
    if(origLeft !== 0) {
      elem.style.left =  `${leftPos}px`;
    } else {
      elem.style.left =  `${center}px`;
    }
    elem.style.transform = "translateX(-50%)";
    // Append the indicator to the DOM
    nav.prepend(elem);
    // Get reference to the indicator
    setIndicator(document.getElementById("indicator"));
  }

  const addEvents = (links) => {
    // Listen to hover events on Links and update the position of the indicator
    links.forEach(link => link.addEventListener("mouseover", e => eventHandler(e) ));
    links.forEach(link => link.addEventListener("mouseout", e => eventHandler(e) ));
    window.addEventListener("resize", function() {
      updateIndicatorPosition();
    });
  }

  const updateIndicatorPosition = () => {
    if(window.innerWidth > 640) {
      setIsOpen(false);
    }

    if(activeLink !== null) {
      const centerOrig = (activeLink.offsetWidth / 2);
      const origLeft = activeLink.offsetLeft;
      const leftPos = origLeft + centerOrig;

      if(origLeft !== 0) {
        indicator.style.left = `${leftPos}px`;
        indicator.style.transform = "translateX(-50%)";
      } else {
        indicator.style.left = `${centerOrig}px`;
        indicator.style.transform = "translateX(-50%)";
      }
    }
  }

  const eventHandler = evt => {
    const centerOrig = (activeLink.offsetWidth / 2);
    const origLeft = activeLink.offsetLeft + centerOrig;
    const centerElem = (evt.target.offsetWidth / 2);
    const leftPos = evt.target.offsetLeft + centerElem;

    if(evt.type === "mouseover") {
      // Slide Indicator to Target Link
      if(leftPos !== 0) {
        indicator.style.left = `${leftPos}px`;
        indicator.style.transform = "translateX(-50%)";
      } else {
        indicator.style.left = leftPos;
        indicator.style.transform = "translateX(-50%)";
      }
    } else {
      // Slide the Indicator back to the current active link
      if(origLeft !== 0) {
        indicator.style.left = `${origLeft}px`;
        indicator.style.transform = "translateX(-50%)";
      } else {
        indicator.style.left = origLeft;
        indicator.style.transform = "translateX(-50%)";
      }
    }
  }

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  }

  const clickHandler = e => {
    const link = e.target.innerText;
    if(link === undefined) {
      setActiveNav('about');
    }
    else {
      setActiveNav(link);
    }
    
    setIsOpen(false);
  }
  
  return (
    <nav className="fixed z-30 top-0 left-0 right-0 flex items-center">
      <div className="container mx-auto w-11/12 md:w-4/5 flex items-center justify-between">
        {/* Brand Logo */}
        <div className="w-4/12 md:w-5/12 lg:w-6/12">
          <Spring
            from={{ transform: 'scale(4)' }}
            to={{ transform: 'scale(1)' }}
            config={ config.wobbly }
            >
            {props => (
              <Logo id="brand-logo" className="cursor-pointer" onClick={clickHandler} style={props}/>
            )}
          </Spring>
        </div>

        {/* Sidenav Trigger */}
        <div className="menu-icon-container">
          <Spring
            from={{ transform: 'scale(0)' }}
            to={{ transform: 'scale(1)' }}
            config={ config.wobbly }
            reset={isOpen}
            >
            {props => (
              <>
                { isOpen ? <CloseIcon style={props} className="cursor-pointer" onClick={toggleMenu} /> : <MenuIcon style={props} className="cursor-pointer" onClick={toggleMenu} /> }
              </>
            )}
          </Spring>
        </div>
        
        {/* Main Navigation */}
        <Spring
          from={{ position: 'relative', top: 50, opacity: 0 }}
          to={{ top: 0, opacity: 1 }}
          config={ config.wobbly }
          >
          {props => (
            <ul style={props} id="main-nav" className="relative list-none h-max w-8/12 md:w-7/12 lg:w-6/12 flex justify-between">
              {navLinks.map(link => (
                <li key={link} className={activeNav !== link ? "link" : "link active"} onClick={clickHandler}>
                  {link}
                </li>
              ))}
            </ul>
          )}
        </Spring>
        
        {/* Sidenav */}
        <ul id="mobile-nav" className={isOpen ? "open list-none h-max w-5/12 space-y-8" : "list-none h-max w-5/12 space-y-8"}>
          <Trail
            items={navLinks}
            keys={item => item}
            from={{ transform: 'scale(0)', opacity: 0 }}
            to={{ transform: 'scale(1)', opacity: 1 }}
            delay={200}
            config={config.wobbly}
            reset={true}
            >
            {link => (props) => (
              <li style={props} key={link} className={activeNav !== link ? "link select-none" : "link active select-none"} onClick={clickHandler}>
                {link}
              </li>
            )}
          </Trail>
        </ul>
      </div>
    </nav>
  )
}

export default Nav;