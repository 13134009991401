import { ReactComponent as EmailIcon } from '../../assets/icons/email-icon.svg';
import { ReactComponent as LinkedInIcon } from '../../assets/icons/linkedIn-icon.svg';
import { ReactComponent as GithubIcon } from '../../assets/icons/github-icon.svg';

export const user_config = {
  id: "user_I2APdQvEeTn7LGyYJ8sDO",
  service: "service_a2o3r0o"
};

export const social_links = [
  {
    icon: EmailIcon,
    url: "mailto:jmjaropojop92@gmail.com",
    label: "jmjaropojop92@gmail.com"
  },
  {
    icon: LinkedInIcon,
    url: "https://www.linkedin.com/in/jerome-mico-jaropojop-142350171",
    label: "Jerome Mico Jaropojop"
  },
  {
    icon: GithubIcon,
    url: "https://github.com/jmjaro-dev",
    label: "jmjaro-dev"
  }
]