import Covid19Thumbnail from '../../assets/thumbnails/covid-tracker-mockup.png';
import ITTicketingSystemThumbnail from '../../assets/thumbnails/ticketing-system-mockup.PNG';
import SpaceXAppThumbnail from '../../assets/thumbnails/space-x-mockup.png';
import HEItemBuilderThumbnail from '../../assets/thumbnails/he-item-builder-mockup.png';
import CardMagicTrickThumbnail from '../../assets/thumbnails/21-cards-trick-mockup.png';

export const projectsData = [
  {
    url: "https://space-x-app-angular-graphql.herokuapp.com/",
    img: SpaceXAppThumbnail,
    title: "Space X App",
    technologies: ["Angular2", "TypeScript", "GraphQL", "ExpressJS", "NodeJs", "Axios", "Angular-Material", "Apollo-client", "SASS" ],
    desc: "A Web Application that fetches information about Space X Missions, Rockets, Capsules and more. I used GraphQL to fetch and extract information from the SpaceX API @ https://api.spacexdata.com/v4.",
    status: "online"
  },
  {
    url: "https://mern-it-ticketing-system.herokuapp.com/",
    img: ITTicketingSystemThumbnail,
    title: "Ticketing System",
    technologies: ["ReactJS", "MongoDB", "ExpressJS", "NodeJs", "Axios", "MaterializeCSS", "bcrypt", "JWT", "Mongoose", "Redux", "redux-thunk"],
    desc: "A Ticketing System where an Employee and an IT Personnel (Technician) can collaborate to fix a Hardware/Software issue in the office by issuing a Ticket for each issue.",
    status: "online"
  },
  {
    url: "https://jmj-covid-19-tracker.netlify.app/",
    img: Covid19Thumbnail,
    title: "Covid-19 Tracker",
    technologies: [ "ReactJS", "NodeJs", "Axios", "Material-UI" ],
    desc: "A simple Novel Corona Virus (COVID-19) tracker that displays the latest information of total confirmed cases, deaths, active cases, critical cases, total recoveries and total tests conducted around the Globe and for each countries. It also shows the Top 10 Countries that has the most type of cases.",
    status: "online" 
  },
  {
    url: "https://21-cards-magic-trick.netlify.app/",
    img: CardMagicTrickThumbnail,
    title: "21 Cards Trick",
    technologies: [ "ReactJS", "NodeJs", "SASS" ],
    desc: "This is a coded implementation of the 21 Cards Magic Trick that my brother taught me a long time ago. This trick is self-solving. I added 2 variations of the original implementation of this trick. It will guess your choosen card 101%. Try it yourself and be amazed.",
    status: "online" 
  },
  {
    url: "https://he-item-builder-v1.netlify.app/",
    img: HEItemBuilderThumbnail,
    title: "HE Item Builder",
    technologies: [ "ReactJS", "NodeJs", "SASS", "PWA" ],
    desc: "This is the improved version of the in-game Heroes Evolved Mobile Item Builder feature. I added summary information of the item build, like the total cost and breakdown of each item, Unique Passives gained, Active Item effects, and total Stats gained from the build.",
    status: "online" 
  }
]