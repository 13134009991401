import { projectsData } from './ProjectsData'; 
import { Spring, config } from 'react-spring/renderprops';
// Paginator Component
import Paginator from '../layout/paginator/Paginator';

const Projects = ({ activeNav, activeProject, setActiveProject }) => {
  return (
    <section id="projects" className={activeNav === "projects" ? "visible" : "hidden"}>
      <h2 className="text-watermark absolute select-none">Projects</h2>
        {/* Projects */}
        <div className="project-container flex flex-col h-full w-full mx-auto justify-between">
          { projectsData.map((project, idx) => (
            <div key={idx} className={(activeProject - 1) === idx ? "visible h-full" : "hidden"}>
              <div className="project-item pointer-events-auto" key={project.title}>
                <div className="container w-11/12 flex flex-col justify-evenly md:w-4/5 md:flex-row md:justify-center items-center mx-auto h-full">
                  {/* Project Info */}
                  <div className="project-info-container flex flex-col justify-center h-1/3 sm:h-full md:w-1/2">
                    {/* Project Title */}
                    {activeNav === "projects" && 
                      <Spring 
                        from = {{ transform: 'scale(2)', opacity: 0 }}
                        to = {{ transform: 'scale(1)', opacity: 1 }}
                        config = {config.wobbly} 
                        reset = { true }
                      >
                        {props =>
                          <>
                            <h1 style={props} className="relative w-max mb-4 sm:mb-10 select-none">
                              {project.title}
                            </h1>
                            <span className="absolute project-status rounded-full bg-green-500 animate-pulse"></span>
                          </>
                        }
                      </Spring>
                    }
      
                    {/* Project Decription */}
                    {activeNav === "projects" && 
                      <Spring 
                        from = {{ position: 'relative', top: 20, opacity: 0 }}
                        to = {{ top: 0, opacity: 1 }}
                        delay = { 1000 }
                        config = {{ duration: 300 }}
                        reset = { true }
                      >
                        {props => 
                          <p style={props} className="mb-4 sm:mb-10 text-sm sm:text-base tracking-wider text-justify">
                            {project.desc}
                          </p>  
                        }
                      </Spring>
                    }
      
                    {/* Technologies used */}
                    <div className="mb-4 sm:mb-10">
                      {activeNav === "projects" && 
                        <Spring 
                          from = {{ position: 'relative', left: 100, opacity: 0 }}
                          to = {{ left: 0, opacity: 1 }}
                          delay = { 1500 }
                          config = {{ duration: 300 }}
                          reset = { true }
                        >
                          {props => 
                            <p style={props} className="text-xs sm:text-sm mr-3 tracking-widest">
                            Built with: {' '}
                              {project.technologies.map((tech, idx) => (
                                <span key={tech} className="text-xs sm:text-sm text-gray-400 font-light tracking-widest">
                                  {idx !== (project.technologies.length - 1) ? `${tech}, `: `and ${tech}.` }
                                </span>
                              ))}
                            </p> 
                          }
                        </Spring>
                      }
                    </div>
      
                    {/* Website Link */}
                    {activeNav === "projects" && 
                      <Spring 
                        from = {{ transform: 'scale(0)', opacity: 0 }}
                        to = {{ transform: 'scale(1)', opacity: 1 }}
                        delay = { 1800 }
                        config = {config.wobbly}
                        reset = { true }
                      >
                        {props => 
                          <div style={props} className="btn-primary cursor-pointer select-none mb-2 w-max px-3 py-2 sm:px-5 sm:py-3">
                            <a className="text-sm sm:text-normal font-medium select-none tracking-widest" href={project.url} target="_blank" rel="noopener noreferrer">Visit Website</a> 
                          </div>  
                        }
                      </Spring>
                    }
                  </div>
      
                  {/* Project Thumbnail */}
                  {activeNav === "projects" && 
                    <Spring 
                      from = {{ transform: 'scale(2)', opacity: 0 }}
                      to = {{ transform: 'scale(1)', opacity: 1 }}
                      config = {config.default} 
                      delay = { 200 }
                      reset = { true }
                    >
                      {props =>
                        <div style={props} className="project-image-container flex justify-center w-11/12 order-first md:w-1/2 md:order-none lg:w-4/6">
                          <img 
                            className="thumbnail w-4/5 select-none"
                            src={project.img} 
                            alt={project.title} 
                          />
                        </div>
                      }
                    </Spring>
                  }
                </div>
              </div>
            </div>
          ))}
          {/* Paginator */}
          <Paginator activeNav= {activeNav} activeProject={activeProject} setActiveProject={setActiveProject} />
        </div>
    </section>
  )
}

export default Projects;