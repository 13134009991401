import { useState, useEffect } from 'react';
import { ReactComponent as PrevIcon } from '../../../assets/icons/previous-icon.svg';
import { ReactComponent as NextIcon } from '../../../assets/icons/next-icon.svg';

const Paginator = ({ activeNav, activeProject, setActiveProject }) => {
  const [isInitialized, setIsInitialized] = useState(false);
  const [isIndicatorInitialized, setIsIndicatorInitialized] = useState(false);
  const [paginator, setPaginator] = useState(null);
  const [pageIndicator, setPageIndicator] = useState(null);
  const [pages, setPages] = useState(null);
  const [currentPage, setCurrentPage] = useState(null);
  const pageNumbers = [1, 2, 3, 4 ,5];

  useEffect(() => {
    // Initialize the Paginator, Pages, and currentPage
    if(!isInitialized && !isIndicatorInitialized && paginator === null && pages === null && activeNav === "projects") {
      initIndicator();
      // console.log("paginator and pages initialized");
    }

    // Set the position of the Indicator
    if(isInitialized && !isIndicatorInitialized && paginator !== null && pages !== null && activeNav === "projects") {
      setInitialPos(currentPage);
      setCurrentPage(document.querySelector(".page-number.active"));
      // console.log("page indicator initialized");
    }

    // Update the position of the Indicator whenever the currentPage changes
    if(isInitialized && isIndicatorInitialized && paginator !== null && pages !== null && activeNav === "projects") {
      setCurrentPage(document.querySelector(".page-number.active"));
      updateIndicatorPos();
      // console.log("Indicator position updated");
      window.addEventListener("resize", function() {
        updateIndicatorPos();
      });
    }
    // eslint-disable-next-line
  }, [activeProject, currentPage, isInitialized, isIndicatorInitialized, pageIndicator, paginator, pages, activeNav])

  // Initializes the Paginator and Pages by getting it's reference
  const initIndicator = () => {
    // Get Reference of the Paginator
    setPaginator(document.getElementById("paginator"));
    // Get reference of the pages
    setPages(document.querySelectorAll(".page"));
    // Get reference of the Current Page
    setCurrentPage(document.querySelector(".page-number.active"));
    // Set isInitialized to true
    setIsInitialized(true);
  } 

  // Initialize the Initial Position for the Page Indicator
  const setInitialPos = (currentPage) => {
    // Create indicator element
    let elem = document.createElement("div");
    elem.id = "page-indicator";
    // Set Indicator element left position
    const origLeft = currentPage.parentElement.offsetLeft;
    const center = currentPage.parentElement.offsetWidth / 2;
    const leftPos = origLeft + center;
    if(origLeft !== 0) {
      elem.style.left =  `${leftPos}px`;
    } else {
      elem.style.left =  `${center}px`;
    }
    // Append the indicator to the DOM
    paginator.prepend(elem);
    // Get reference to the indicator
    setPageIndicator(document.getElementById("page-indicator"));
    // Set isIndicatorInitialized to true
    setIsIndicatorInitialized(true);
  }

  const updateIndicatorPos = () => {
    if(currentPage !== null) {
      // Update Indicator left position
      const origLeft = currentPage.parentElement.offsetLeft;
      const center = currentPage.parentElement.offsetWidth / 2;
      const leftPos = origLeft + center;
      if(origLeft !== 0) {
        pageIndicator.style.left = `${leftPos}px`;
      } else {
        pageIndicator.style.left = `${center}px`;
      }
    }
  }

  const onPrevPage = () => {
    setActiveProject(activeProject-1);
  }

  const onNextPage = () => {
    setActiveProject(activeProject+1);
  }

  const onClickHandler = e => {
    const target = Number(e.target.innerText);
    setActiveProject(target);
  }

  return (
    <div id="paginator" className="relative z-10 w-full flex items-center justify-center pointer-events-auto">
      {/* Previous Icon */}
      <div className="icon-container">
        {activeProject !== 1 && <PrevIcon width="10px" className="cursor-pointer icon" onClick={onPrevPage} />}
      </div>

      {/* Page Numbers  */}
      {pageNumbers.map(page => (
        <div key={page} className="page-container w-max text-center">
          <span className={activeProject === page ? "page-number active select-none" : "page-number select-none"} onClick={onClickHandler}>{page}</span>
        </div>
      ))}

      {/* Next Icon */}
      <div className="icon-container">
        {activeProject !== 5 && <NextIcon width="10px" className="cursor-pointer icon" onClick={onNextPage} /> }
      </div>
    </div>
  )
}

export default Paginator;