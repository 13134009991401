// Logos
import HTML5Logo from '../../assets/logos/html5-logo.png';
import CSSLogo from '../../assets/logos/css3-logo.png';
import JSLogo from '../../assets/logos/javascript-logo.png';
import TSLogo from '../../assets/logos/typescript-logo.png';
import PHPLogo from '../../assets/logos/php-logo.png';
import GraphQLLogo from '../../assets/logos/graphql-logo.png';
import SASSLogo from '../../assets/logos/sass-logo.png';
import MongoDBLogo from '../../assets/logos/mongodb.png';
import FirebaseLogo from '../../assets/logos/firebase-logo.png';
import MySQLLogo from '../../assets/logos/mysql-logo.png';
import PostgreSQLLogo from '../../assets/logos/postgresql-logo.png';
import ExpressLogo from '../../assets/logos/express-logo.png';
import ReactJSLogo from '../../assets/logos/react-logo.png';
import AngularLogo from '../../assets/logos/angular-logo.png';
import NestJSLogo from '../../assets/logos/nestjs-logo.png';
import MUILogo from '../../assets/logos/material-ui-logo.png';
import MaterializeCSSLogo from '../../assets/logos/materializecss-logo.png';
import TailwindCSSLogo from '../../assets/logos/tailwind-logo.png';
import BootstrapLogo from '../../assets/logos/bootstrap-logo.png';
import InsomniaLogo from '../../assets/logos/insomnia-logo.png';
import PostmanLogo from '../../assets/logos/postman-logo.jpeg';
import FigmaLogo from '../../assets/logos/figma-logo.png';
import XDLogo from '../../assets/logos/adobe-xd-logo.png';
import GitLogo from '../../assets/logos/git-logo.png';
import GitHubLogo from '../../assets/logos/github-logo.png';

export const languages = [
  {
    name: 'HTML5',
    logo: HTML5Logo
  },
  {
    name: 'CSS3',
    logo: CSSLogo
  },
  {
    name: 'Javascript',
    logo: JSLogo
  },
  {
    name: 'TypeScript',
    logo: TSLogo
  },
  {
    name: 'PHP',
    logo: PHPLogo
  },
  {
    name: 'GraphQL',
    logo: GraphQLLogo
  },
  {
    name: 'MongoDB',
    logo: MongoDBLogo
  },
  {
    name: 'Firebase',
    logo: FirebaseLogo
  },
  {
    name: 'MySQL',
    logo: MySQLLogo
  },
  {
    name: 'PostgreSQL',
    logo: PostgreSQLLogo
  }
];

export const frameworks = [
  {
    name: 'ReactJS',
    logo: ReactJSLogo
  },
  {
    name: 'Angular 2',
    logo: AngularLogo
  },
  {
    name: 'MaterialUI',
    logo: MUILogo
  },
  {
    name: 'MaterializeCSS',
    logo: MaterializeCSSLogo
  },
  {
    name: 'Tailwind',
    logo: TailwindCSSLogo
  },
  {
    name: 'Bootstrap',
    logo: BootstrapLogo
  },
  {
    name: 'ExpressJS',
    logo: ExpressLogo
  },
  {
    name: 'NestJS',
    logo: NestJSLogo
  }
];

export const tools = [
  {
    name: 'Git',
    logo: GitLogo
  },
  {
    name: 'Github',
    logo: GitHubLogo
  },
  {
    name: 'SASS',
    logo: SASSLogo
  },
  {
    name: 'Insomnia',
    logo: InsomniaLogo
  },
  {
    name: 'Postman',
    logo: PostmanLogo
  },
  {
    name: 'Figma',
    logo: FigmaLogo
  },
  {
    name: 'Adobe XD',
    logo: XDLogo
  }
];