import { useState } from 'react';
import { Spring, Trail, config } from 'react-spring/renderprops';
import emailjs from 'emailjs-com';
import { user_config, social_links } from './config';

const Contact = ({ activeNav, setFeedback, showNotif }) => {
  const initialState = {
    user_name: "",
    user_email: "",
    message: ""
  };

  const [mail, setMail] = useState(initialState);
  const [loading, setLoading] = useState(false);
  
  const onChange = e => {
    setMail({
      ...mail,
      [e.target.name]: e.target.value
    });
  }

  const resetStates = () => {
    setMail(initialState);
  }

  const sendEmail = async e => {
    e.preventDefault();
    setFeedback('Sending message...');
    showNotif();
    setLoading(true);
    emailjs.sendForm(user_config.service, 'contact_form', e.target, user_config.id)
      .then((result) => {
        if(result.text === "OK"){
          setFeedback("Message sent.");
          showNotif();
          setLoading(false);
          resetStates();
        }
      }, (error) => {
          console.log(error.text);
          setFeedback("Send failed. Something went wrong.");
          showNotif();
          setLoading(false);
          resetStates();
      });
  }

  return (
    <section id="contact" className={activeNav === "contact" ? "visible pointer-events-auto" : "hidden"} >
      <h2 className="text-watermark absolute select-none">Contact</h2>
      <div className="container w-11/12 justify-center md:w-4/5 mx-auto h-full flex flex-col md:flex-row md:justify-center">
        {/* Header with Social links */}
        <div className="social-links-container flex flex-col w-full h-max justify-start md:w-1/2 md:h-full md:items-center md:justify-center">
          {/* Get in Touch Animation */}
          {activeNav === 'contact' && 
            <Spring 
              from = {{ transform: 'scale(3)', opacity: 0 }}
              to = {{ transform: 'scale(1)', opacity: 1 }}
              config = {config.wobbly}
              delay = { 500 }
            >
              {props =>
                <h1 style={props} className="w-max select-none md:mb-12">
                  Get in Touch
                </h1>
              }
            </Spring> 
          }
          
          {/* Social Links Trail Animation */}
          <div className="links-container hidden md:flex md:flex-col md:justify-center">
            {activeNav === 'contact' && 
              <Trail
                items={social_links}
                keys={item => item.label}
                from={{ position: 'relative', top: 50, opacity: 0 }}
                to={{ top: 0, opacity: 1 }}
                delay={1000}
                config={config.gentle}
                >
                {link => (props) => (
                  <div style={props} className="link-item flex items-center" key={link.label}>
                    <link.icon height="30px" width="30px" />
                    <a className="cursor-pointer" href={link.url} target="_blank" rel="noopener noreferrer">{link.label}</a>
                  </div>
                )}
              </Trail>
            }
          </div>
        </div>
        
        {/* Contact Form Animation */}
        {activeNav === 'contact' && 
          <Spring 
            from = {{ transform: 'scale(0)', opacity: 0 }}
            to = {{ transform: 'scale(1)', opacity: 1 }}
            config = {config.wobbly}
            delay = { 1200 }
          >
            {props =>
              <div style={props} className="contact-form-container flex flex-col w-full h-max mt-5 md:mt-0 justify-start md:w-1/2 lg:h-4/5 lg:my-auto md:justify-center">
                {/* Sub Header */}
                <div className="sub-header select-none tracking-wider w-max px-4 py-2 sm:px-6 sm:py-2 mb-4">
                  Send a Message 
                </div>
                
                {mail && (
                  <form id="contact-form" onSubmit={sendEmail}  className="flex flex-col w-full lg:w-4/5">
                    {/* <label className="text-gray-50">Name</label> */}
                    <input className="px-4 py-3 mb-4 tracking-wider outline-none focus:ring-2 focus:ring-red-700" value={mail.user_name} type="text" name="user_name" placeholder="Your Name" onChange={onChange} required disabled={loading} />
      
                    {/* <label className="text-gray-50">Email</label> */}
                    <input className="px-4 py-3 mb-4 tracking-wider outline-none focus:ring-2 focus:ring-red-700" value={mail.user_email} type="email" name="user_email" placeholder="Your Email" onChange={onChange} required disabled={loading} />
      
                    {/* <label className="text-gray-50" placeholder="Message">Message*</label> */}
                    <textarea className="px-4 py-3 mb-4 tracking-wider outline-none focus:ring-2 focus:ring-red-700 resize-none" name="message" placeholder="Message" maxLength="500" rows="5" value={mail.message} onChange={onChange} required disabled={loading} />
      
                    {/* Send Button */}
                    <div className="flex justify-between sm:justify-end">
                      {/* Social Links Trail Animation */}
                      <div className="small-links-container sm:hidden flex justify-center">
                        {activeNav === 'contact' && 
                          <Trail
                            items={social_links}
                            keys={item => item.label}
                            from={{ position: 'relative', top: 20, opacity: 0 }}
                            to={{ top: 0, opacity: 1 }}
                            config={config.wobbly}
                            delay={1800}
                            >
                            {link => (props) => (
                              <div style={props} className="link-item flex items-center mr-5" key={link.label}>
                                <a className="cursor-pointer" href={link.url} target="_blank" rel="noopener noreferrer">
                                  <link.icon height="25px" width="25px" />
                                </a>
                              </div>
                            )}
                          </Trail>
                        }
                      </div>
                      <button type="submit" className="send-btn cursor-pointer px-3 py-2 sm:px-6 sm:py-3 w-max font-medium select-none focus:outline-none" value="Submit" disabled={loading}>Send</button>
                    </div>
                  </form>
                )}
              </div>
            }
          </Spring>
        }
      </div>
    </section>
  )
}

export default Contact;