import { Spring, config } from 'react-spring/renderprops';
// CV
import CV from '../../assets/docs/JEROME MICO JAROPOJOP.pdf';

const About = ({ activeNav }) => {

  return (
    <section id="about" className={activeNav === "about" ? "visible pointer-events-auto" : "hidden"} >
      <h2 className="text-watermark absolute select-none">About</h2>
      <div className="container mx-auto flex w-11/12 sm:w-4/5 h-full flex-col sm:justify-center">
        {/* h3 Animation */}
        {activeNav === "about" &&
          <Spring 
            from = {{ position: 'relative', bottom: 50, opacity: 0 }}
            to = {{ bottom: 0, opacity: 1 }}
            config = {config.wobbly}
            delay = { 500 } 
          >
            {props => <h3 style={props} className="text-5xl tracking-wider md:mb-10 select-none">Hello there! I'm,</h3>}
          </Spring>
        }
        
        {/* Name animation */}
        {activeNav === "about" && 
          <Spring 
            from = {{ transform: 'scale(2)', position: 'relative', bottom: 50, opacity: 0 }}
            to = {{ transform: 'scale(1)', bottom: 0, opacity: 1 }}
            delay = { 1000 }
            config = {config.wobbly}
          >
            {props => 
              <h1 style={props} className="tracking-wide mt-14 mb-6 sm:mt-12 sm:mb-5 select-none">
                <span>J</span>erome <span>M</span>ico <span>J</span>aropojop
              </h1>
            }
          </Spring>
        }

        {/* Summary Text  animation */}
        {activeNav === "about" && 
          <Spring 
            from = {{ position: 'relative', top: 20, opacity: 0 }}
            to = {{ top: 0, opacity: 1 }}
            delay = { 2000 }
            config = {{ duration: 300 }}
          >
            {props => 
              <p style={props} className="intro-text mb-12 w-full tracking-widest select-none">
                I'm a web developer based in Philippines. I specialize in making fullstack-web applications.
              </p>   
            }
          </Spring>
        }

        {/* Download CV Button animation */}
        {activeNav === "about" && 
          <Spring 
            from = {{ transform: 'scale(0)', opacity: 0 }}
            to = {{ transform: 'scale(1)', opacity: 1 }}
            delay = { 2500 }
            config = {config.wobbly}
          >
            {props => 
              <div style={props} className="btn-primary cursor-pointer w-max px-3 py-2 sm:px-5 sm:py-3">
                <a className="font-medium text-sm sm:text-normal select-none tracking-widest" href={CV} target="_blank" rel="noopener noreferrer">
                  Download CV
                </a>
              </div> 
            }
          </Spring>
        }
      </div>
    </section>
  )
}

export default About;